import React from "react"
import styled from "styled-components"

// Types
import { IEvents } from "../types/events"

// Styling
import mq from "../styles/breakpoints"

// Components
import EventCard from "./EventCard"

interface IProps {
  events: IEvents
}

const EventCardsWrapper = ({ events }: IProps) => (
  <Container>
    {events.map((event, i) => (
      <StyledEventCard key={i} event={event} />
    ))}
  </Container>
)

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const StyledEventCard = styled(EventCard)`
  width: calc(50% - 6px);
  margin-bottom: 12px;

  :nth-child(odd) {
    margin-right: 6px;
  }

  :nth-child(even) {
    margin-left: 6px;
  }

  ${mq.from.breakpoint.S`
    width: calc(25% - 9px);
    margin-right: 6px;
    margin-left: 6px;

    :first-child {
      margin-left: 0;
    }

    :nth-child(4n+5) {
      margin-left: 0;
    }

    :nth-child(4n+4) {
      margin-right: 0;
    }
  `};

  ${mq.from.breakpoint.M`
    width: calc(16.66666% - 10px);

    :nth-child(4n+5) {
      margin-left: 6px;
    }

    :nth-child(4n+4) {
      margin-right: 6px;
    }

    :nth-child(6n+7) {
      margin-left: 0;
    }

    :nth-child(6n+6) {
      margin-right: 0;
    }
  `};

  ${mq.from.breakpoint.L`
    width: calc(12.5% - 10.5px);

    :nth-child(6n+7) {
      margin-left: 6px;
    }

    :nth-child(6n+6) {
      margin-right: 6px;
    }

    :nth-child(8n+9) {
      margin-left: 0;
    }

    :nth-child(8n+8) {
      margin-right: 0;
    }
  `};
`

export default EventCardsWrapper
