import React, { useContext } from "react"
import styled from "styled-components"

// Context
import Context from "../context/context"

// Images
import tessaImage from "../images/tessa.gif"
import rilliImage from "../images/rilli.gif"
import imkeImage from "../images/imke.gif"
import fransImage from "../images/frans.gif"

// Styling
import { slowTransition } from "../styles/animations"

const Party = () => {
  const app = useContext(Context)
  return (
    <Container active={app !== null && app.party}>
      <Image src={tessaImage} alt="Tessa = party" />
      <Image src={fransImage} alt="Frans = party" />
      <Spacing />
      <Image src={imkeImage} alt="Imke = party" />
      <Image src={rilliImage} alt="Rilli = party" />
    </Container>
  )
}

const Container = styled.div`
  z-index: 10;
  pointer-events: none;
  position: fixed;
  justify-content: space-around;
  align-items: flex-end;
  bottom: 0;
  left: 0;
  width: 100vw;
  display: flex;
  transform: translateY(100%);
  transition: transform ${slowTransition};

  > img {
    margin-bottom: 0;
  }

  ${({ active }: { active: boolean }) =>
    active &&
    `
    transform: translateY(0);
  `};
`

const Image = styled.img`
  width: 200%;
`

const Spacing = styled.div`
  min-width: 300px;
  height: 1px;
`

export default Party
