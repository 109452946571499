import React from "react"

// Types
import { IIconProps } from "./Icon"

const PresentOpen = ({ width, height, color, className }: IIconProps) => (
  <svg
    className={className}
    style={{
      width: `${String(width)}px`,
      height: `${String(height)}px`,
    }}
    viewBox="0 0 75 93"
    fill={color}
  >
    <g fill={color} fillRule="evenodd">
      <path d="M38.4 92.34H6.54V63.315H38.4V92.34zm4.111 0h31.862V63.315H42.51V92.34zM30.266 28.451L.003 45.923 6.68 57.486 36.94 40.014zM33.826 26.395l6.676 11.563 30.263-17.473L64.09 8.923zM37.257 1.882c9.775-5.643 17.888 8.647 8.15 14.27l-14.902 8.603c-.555-5.456-1.464-18.13 6.752-22.873zm3.472 11.04c4.239-2.447 1.002-7.693-2.25-5.815-2.51 1.448-3.126 4.862-3.42 9.09zM7.321 19.166c8.216-4.744 18.737 2.38 23.184 5.589l-14.9 8.603C5.865 38.98-2.454 24.809 7.32 19.166zm13.496 5.253c-3.808-1.859-7.075-3.03-9.582-1.584-3.253 1.879-.327 7.305 3.911 4.858z" />
    </g>
  </svg>
)

export default PresentOpen
