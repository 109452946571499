import React from "react"
import styled from "styled-components"

// Styling
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"
import mq from "../styles/breakpoints"
import { fastTransition, slowTransition } from "../styles/animations"

// Components
import Icon from "../components/icons/Icon"

interface IProps {
  onClick: () => void
  show: boolean
  className?: string
}

const Splash = ({ onClick, show, className }: IProps) => (
  <Container className={className} show={show}>
    <Content>
      <StyledIcon
        icon="tree"
        color={colors.yellow}
        width={180}
        height={180}
        direction="right"
      />
      <Title onClick={() => onClick()}>Click for joy</Title>
      <StyledIcon
        icon="tree"
        color={colors.yellow}
        width={180}
        height={180}
        direction="left"
      />
    </Content>
  </Container>
)

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  transition: transform ${slowTransition}, opacity ${slowTransition};
  transform-origin: center center;

  ${({ show }: { show: boolean }) =>
    !show &&
    `
    opacity: 0;
    transform: scale(1.2);
    pointer-events: none;
  `};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 1024px;
  height: 100%;
  margin: 0 auto;

  ${mq.from.breakpoint.M`
    flex-direction: row;
    justify-content: space-between;
  `};
`

const Title = styled.h1`
  ${textStyles.pageTitle};
  cursor: pointer;
  text-align: center;
  transition: color ${fastTransition};

  :hover {
    color: ${colors.red};
  }
`

const StyledIcon = styled(Icon)`
  @keyframes floatRight {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(24px);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes floatLeft {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-24px);
    }
    100% {
      transform: translateX(0);
    }
  }

  :first-child {
    animation: floatRight 1.2s ease-in-out infinite;
  }

  :last-child {
    animation: floatLeft 1.2s ease-in-out infinite;
  }
`

export default Splash
