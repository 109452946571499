import React, { useState, useEffect, createRef } from "react"
import styled from "styled-components"

// Types
import { IEvent } from "../types/events"

// Images
import sparklesGif from "../images/sparkles.gif"

// Sounds
import bellsSound from "../images/bells.mp3"
import magicSound from "../images/magic.mp3"

// Styling
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"

// Components
import ModalCard from "./ModalCard"
import Icon from "./icons/Icon"

interface IProps {
  event: IEvent
  className?: string
}

const EventCard = ({ event, className }: IProps) => {
  const bellsSoundRef = createRef<HTMLAudioElement>()
  const magicSoundRef = createRef<HTMLAudioElement>()

  const [showModal, setShowModal] = useState<boolean>(false)
  const [locked, setLocked] = useState<boolean>(false)
  const [passed, setPassed] = useState<boolean>(false)
  const [currentEvent, setCurrentEvent] = useState<boolean>(false)

  const handleEventLock = () => {
    const date = new Date()
    const year = date.getFullYear()
    const month = date.getMonth()
    const currentDay = date.getDate()
    const currentHours = date.getHours()
    const currentMinutes = date.getMinutes()

    if (year === 2019) {
      // ? To test in november change 11 to 10
      if (month < 11) {
        setLocked(true)
      } else if (month === 11) {
        if (event.day < currentDay) {
          setLocked(false)
          setPassed(true)
        } else if (event.day === currentDay) {
          if (currentHours < 12) {
            setLocked(true)
          } else if (currentHours === 12) {
            if (currentMinutes < 30) {
              setLocked(true)
            } else if (currentMinutes >= 30) {
              setLocked(false)
              setCurrentEvent(true)
            }
          } else if (currentHours > 12) {
            setLocked(false)
            setCurrentEvent(true)
          }
        } else if (event.day > currentDay) {
          setLocked(true)
        }
      }
    } else {
      setLocked(false)
      setPassed(true)
    }
  }

  const handleShowModal = () => {
    if (bellsSoundRef.current) {
      bellsSoundRef.current.play()
    }
    setShowModal(true)
  }

  const playMagicSound = () => {
    if (currentEvent) {
      if (magicSoundRef.current) {
        magicSoundRef.current.play()
      }
    }
  }

  const stopMagicSound = () => {
    if (currentEvent) {
      if (magicSoundRef.current) {
        magicSoundRef.current.pause()
        magicSoundRef.current.currentTime = 0
      }
    }
  }

  useEffect(() => {
    handleEventLock()
  }, [])

  return (
    <div className={className}>
      <ModalCard
        event={event}
        open={showModal}
        closeModal={() => setShowModal(false)}
      />

      <CardContainer
        currentEvent={currentEvent}
        onMouseEnter={() => playMagicSound()}
        onMouseLeave={() => stopMagicSound()}
      >
        <audio ref={bellsSoundRef}>
          <source src={bellsSound} type="audio/mpeg" />
        </audio>
        <audio ref={magicSoundRef} loop>
          <source src={magicSound} type="audio/mpeg" />
        </audio>
        {currentEvent && <Sparkles src={sparklesGif} alt="Sparkles" />}
        <Card
          onClick={() => handleShowModal()}
          locked={locked}
          currentEvent={currentEvent}
        >
          <Day locked={locked} currentEvent={currentEvent}>
            {event.day}
          </Day>
          <Content>
            {locked && (
              <Icon icon="lock" color={colors.white} width={70} height={70} />
            )}
            {currentEvent && (
              <Icon
                icon="present"
                color={colors.yellow}
                width={70}
                height={70}
              />
            )}
            {passed && (
              <StyledIcon
                icon="presentOpen"
                color={colors.grey}
                width={70}
                height={70}
              />
            )}
          </Content>
        </Card>
      </CardContainer>
    </div>
  )
}

const CardContainer = styled.div`
  position: relative;
  ${({ currentEvent }: { currentEvent: boolean }) =>
    currentEvent && `z-index: 1;`};
`

const Sparkles = styled.img`
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 400px;
  min-height: 400px;
  margin-bottom: 0;
`

interface IContainer {
  locked: boolean
  currentEvent: boolean
}
const Card = styled.div<IContainer>`
  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }

  cursor: pointer;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${({ locked, currentEvent }) =>
    locked ? colors.greenDark : currentEvent ? colors.redDark : colors.white};
  text-align: center;

  ${({ locked }) => locked && `pointer-events: none;`};
  ${({ currentEvent }) =>
    currentEvent &&
    `
    box-shadow: 0 0 30px 0 rgba(254,242,212,1);
    :hover { animation shake 1.2s infinite; }
  `};
`

interface IDay {
  locked: boolean
  currentEvent: boolean
}
const Day = styled.h2<IDay>`
  ${textStyles.dayTitle};
  position: relative;
  width: 100%;
  padding: 8px 0;
  background-color: ${({ currentEvent }) =>
    currentEvent ? colors.red : colors.green};

  :after {
    content: "";
    background: ${({ locked, currentEvent }) =>
      locked
        ? `linear-gradient(-45deg, ${colors.greenDark} 4px, transparent 0),
      linear-gradient(45deg, ${colors.greenDark} 4px, transparent 0);`
        : currentEvent
        ? `linear-gradient(-45deg, ${colors.redDark} 4px, transparent 0),
      linear-gradient(45deg, ${colors.redDark} 4px, transparent 0);`
        : `linear-gradient(-45deg, ${colors.white} 4px, transparent 0),
      linear-gradient(45deg, ${colors.white} 4px, transparent 0);`};
    background-position: left-bottom;
    background-repeat: repeat-x;
    background-size: 8px 8px;
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 8px;
  }
`

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
`

const StyledIcon = styled(Icon)`
  margin-left: -4px;
`

export default EventCard
