import React from "react"

// Styling
import colors from "../../styles/colors"

import iconMap from "./iconMap"

export type IIconType = keyof typeof iconMap

export interface IIconProps {
  width?: number
  height?: number
  color?: string
  direction?: "top" | "right" | "bottom" | "left"
  className?: string
}

export type IProps = IIconProps & {
  icon: IIconType
}

const Icon = ({
  icon,
  width = 16,
  height = 16,
  color = colors.white,
  direction,
  className,
}: IProps) => {
  const IconComponent = iconMap[icon]
  return (
    <IconComponent
      className={className}
      width={width}
      height={height}
      color={color}
      direction={direction}
    />
  )
}

export default Icon
