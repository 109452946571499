import React, { useEffect, createRef, useContext, useState } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

// Types
import { IEvents } from "../types/events"

// Context
import Context from "../context/context"

// Sounds
import backgroundSound from "../images/background.mp3"
import partySound from "../images/party.mp3"

// Styling
import { slowTransition } from "../styles/animations"

// Component
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Splash from "../components/Splash"
import EventCardsWrapper from "../components/EventCardsWrapper"
import Party from "../components/Party"

interface IProps {
  events: IEvents
}

const IndexPage = ({ events }: IProps) => {
  const app = useContext(Context)

  const [enterSite, setEnterSite] = useState(false)

  const backgroundSoundRef = createRef<HTMLAudioElement>()
  const partySoundRef = createRef<HTMLAudioElement>()

  const handleBackgroundSound = () => {
    if (app && app.party === true) {
      if (partySoundRef.current && backgroundSoundRef.current) {
        backgroundSoundRef.current.pause()
        backgroundSoundRef.current.currentTime = 0
        partySoundRef.current.volume = 0.8
        partySoundRef.current.play()
      }
    } else {
      setTimeout(() => {
        if (backgroundSoundRef.current) {
          if (partySoundRef.current) {
            partySoundRef.current.pause()
            partySoundRef.current.currentTime = 0
          }
          backgroundSoundRef.current.volume = 0.6
          backgroundSoundRef.current.play()
        }
      }, 500)
    }
  }

  const handleEnterSite = () => {
    setEnterSite(true)
    if (backgroundSoundRef.current) {
      backgroundSoundRef.current.volume = 0.6
      backgroundSoundRef.current.play()
    }
  }

  useEffect(() => {
    if (enterSite === true) {
      handleBackgroundSound()
    }
  }, [app && app.party])

  return (
    <Layout>
      <audio ref={backgroundSoundRef} loop>
        <source src={backgroundSound} type="audio/mpeg" />
      </audio>
      <audio ref={partySoundRef} loop>
        <source src={partySound} type="audio/mpeg" />
      </audio>
      <Splash show={!enterSite} onClick={() => handleEnterSite()} />
      <Content show={enterSite}>
        <SEO title="Home" />
        <EventCardsWrapper events={events} />
        <Party />
      </Content>
    </Layout>
  )
}

const Content = styled.div`
  pointer-events: none;
  opacity: 0;
  transition: opacity ${slowTransition};

  ${({ show }: { show: boolean }) =>
    show &&
    `
    pointer-events: auto;
    opacity: 1;
  `};
`

const ConnectedIndexPage = () => {
  const data = useStaticQuery(
    graphql`
      query loadPrismicEvents {
        events: allPrismicEvents {
          nodes {
            data {
              day
              title
              description {
                html
              }
              image {
                alt
                url
              }
            }
          }
        }
      }
    `
  )

  const formattedData = data.events.nodes
    .map((item: any) => item.data)
    .sort((a: any, b: any) => (a.day > b.day ? 1 : -1))

  return <IndexPage events={formattedData} />
}

export default ConnectedIndexPage
