import Lock from "./Lock"
import Present from "./Present"
import PresentOpen from "./PresentOpen"
import Tree from "./Tree"

const iconMap = {
  lock: Lock,
  present: Present,
  presentOpen: PresentOpen,
  tree: Tree,
}

export default iconMap
