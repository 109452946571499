import React from "react"

// Types
import { IIconProps } from "./Icon"

const Present = ({ width, height, color, className }: IIconProps) => (
  <svg
    className={className}
    style={{
      width: `${String(width)}px`,
      height: `${String(height)}px`,
    }}
    viewBox="0 0 74 66"
    fill={color}
  >
    <path
      d="M34.944 66H3.084V36.973h31.86V66zm0-46.486H0v13.35h34.944v-13.35zM39.056 66h31.86V36.973h-31.86V66zm0-46.486v13.35H74v-13.35H39.056zM54.284 0C44.797 0 39.247 11.43 37 16.432h17.207C65.45 16.432 65.57 0 54.284 0zM51.77 11.297h-6.548c2.369-3.513 4.61-6.162 7.507-6.162 3.756 0 3.935 6.162-.959 6.162zm-31.977 5.135H37C34.753 11.43 29.203 0 19.716 0 8.43 0 8.55 16.432 19.793 16.432zm8.985-5.135H22.23c-4.894 0-4.715-6.162-.959-6.162 2.895 0 5.138 2.649 7.507 6.162z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
)

export default Present
