import React from "react"
import styled from "styled-components"

// Types
import { IIconProps } from "./Icon"

const Tree = ({ width, height, color, direction, className }: IIconProps) => {
  let rotation = "rotate(0deg)"
  if (direction === "right") {
    rotation = "rotate(90deg)"
  } else if (direction === "left") {
    rotation = "rotate(-90deg)"
  } else if (direction === "bottom") {
    rotation = "rotate(180deg)"
  }

  return (
    <Container className={className}>
      <svg
        style={{
          width: `${String(width)}px`,
          height: `${String(height)}px`,
          transform: `${rotation}`,
        }}
        viewBox="0 0 180 180"
        fill={color}
      >
        <path
          d="M180 180l-51.429-60.666h38.572l-51.429-64.666h25.715L90 0 38.571 54.668h25.715l-51.429 64.666H51.43L0 180z"
          fill={color}
          fillRule="evenodd"
        />
      </svg>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
`

export default Tree
